import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

@Component({
  selector: "app-launch-component",
  template: "",
})
export class LaunchComponent implements OnInit {
  private solution: string = null;
  private account: string = null;
  private solutionDefnition: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.solution = params.solution ? params.solution.trim() : null;
      this.account = params.account ? params.account.trim() : null;
      this.solutionDefnition = params.solutionDefnition ? params.solutionDefnition.trim() : null;
    });
  }

  async ngOnInit(): Promise<void> {
    let extras: NavigationExtras = {};
    if (this.solution || this.account) {
      extras = { queryParams: {} };
      if (this.solution) {
        extras.queryParams['solution_hint'] = this.solution;
      }
      if (this.account) {
        extras.queryParams['account_hint'] = this.account;
      }
      if (this.solutionDefnition) {
        extras.queryParams['solutionDefnition'] = this.solutionDefnition;
      }
    }

    await this.router.navigate(["/login"], extras);
  }
}
